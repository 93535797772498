<template>
  <el-dialog
    title="维护批量信息"
    :visible.sync="maintenanceVisible"
    :close-on-click-modal="false"
    width="500px"
    v-loading="dialogLoading"
    :element-loading-background="loadingBackground"
    custom-class="custom-dialog"
    :before-close="closeMaintenanceDialog"
    :modal-append-to-body="false"
  >
    <el-form
      :model="maintenanceForm"
      label-width="200px"
      ref="maintenanceForm"
      :rules="maintenanceForm.isBatch ? maintenanceFormRulers : {}"
    >
      <!-- :rules="formRule" -->
      <el-form-item label="是否批量单：" label-width="60" prop="isBatch">
        <el-switch
          v-model="maintenanceForm.isBatch"
          :active-value="true"
          :inactive-value="false"
        >
        </el-switch>
      </el-form-item>
      <el-form-item
        label="关单核算时间："
        label-width="60"
        prop="dailyBatchTime"
      >
        <el-date-picker
          v-model="maintenanceForm.dailyBatchTime"
          placement="bottom-start"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="关单核算时间"
          popper-class="special"
        ></el-date-picker>
      </el-form-item>
    </el-form>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="closeMaintenanceDialog">取消</el-button>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { cleanDailyBatch, addDailyBatch } from "@/api";
import { printError } from "@/utils/util";
import base from "@/mixins/base";
export default {
  name: "maintenanceDialog",
  components: {},
  props: ["maintenanceVisible", "taskIdList"],
  data() {
    return {
      dialogLoading: false,
      maintenanceFormRulers: {
        dailyBatchTime: [
          {
            required: true,
            message: "请选择关单核算时间",
            trigger: ["blur", "change"]
          }
        ]
      },
      maintenanceForm: {
        isBatch: true,
        dailyBatchTime: ""
      }
    };
  },
  mixins: [base],
  computed: {},
  watch: {},
  methods: {
    //日常批量
    async addDailyBatchReq() {
      try {
        this.dialogLoading = true;
        let res = await addDailyBatch({
          taskIdList: this.taskIdList,
          dailyBatchTime: this.maintenanceForm.dailyBatchTime
        });
        if (res.code == "OK") {
          this.$message.success("操作成功");
          this.$emit("refresh");
          this.closeMaintenanceDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //日常非批量
    async cleanDailyBatchReq() {
      try {
        this.dialogLoading = true;
        let res = await cleanDailyBatch({
          taskIdList: this.taskIdList,
          dailyBatchTime: this.maintenanceForm.dailyBatchTime
        });
        if (res.code == "OK") {
          this.$message.success("操作成功");
          this.$emit("refresh");
          this.closeMaintenanceDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    closeMaintenanceDialog() {
      this.maintenanceForm = {
        isBatch: true,
        dailyBatchTime: ""
      };
      this.$emit("closeMaintenanceDialog");
    },
    handleSubmit() {
      this.$refs.maintenanceForm.validate(valid => {
        if (valid) {
          if (this.maintenanceForm.isBatch) {
            this.addDailyBatchReq();
          } else {
            this.cleanDailyBatchReq();
          }
        }
      });
    }
  },
  created() {},
  mounted() {}
};
</script>
<style scoped lang="scss">
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 20px 18px 15px 18px;
    .el-form-item {
      .el-input {
        width: 220px;
      }
    }
    .tag-content {
      display: flex;
      align-items: center;
      // justify-content: center;
      flex-wrap: wrap;
    }
    .el-tag {
      margin-right: 10px;
      margin-top: 10px;
    }
  }
}
</style>
