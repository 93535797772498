<!--
 * @Description: 任务单E6&G7
 * @Author: ChenXueLin
 * @Date: 2021-10-13 19:26:59
 * @LastEditTime: 2022-09-15 09:45:29
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title
        @resize="handleTableTitleResize"
        :canSetting="true"
        :tableId="tableId"
        @updateData="queryList"
      >
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="taskNos">
              <!-- <el-input
                v-model="searchForm.taskNos"
                placeholder="任务单号"
                title="任务单号"
              ></el-input> -->
              <input-plus
                v-model="searchForm.taskNos"
                valueType="Array"
                placeholder="任务单号"
                title="任务单号"
                label="任务单号"
                clearable
              ></input-plus>
            </el-form-item>
            <el-form-item class="search-item--1" prop="sourceNumber">
              <el-input
                v-model="searchForm.sourceNumber"
                placeholder="来源单号"
                title="来源单号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="taskSourceList">
              <e6-vr-select
                v-model="searchForm.taskSourceList"
                :data="taskSourceList"
                placeholder="工单来源"
                title="工单来源"
                multiple
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpName">
              <input-select
                v-model="searchForm.corpName"
                :data="corpList"
                placeholder="客户名称"
                title="客户名称"
                clearable
                virtual
                remote
                :is-title="true"
                @filterChange="handleLoadCorpFilter"
                :props="{
                  id: 'corpName',
                  label: 'corpName'
                }"
              ></input-select>
            </el-form-item>
            <el-form-item prop="companyNos">
              <e6-vr-select
                v-model="searchForm.companyNos"
                :data="serviceCompanyList"
                placeholder="签单组织"
                title="签单组织"
                clearable
                multiple
                virtual
                :is-title="true"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpAttrId">
              <e6-vr-select
                v-model="searchForm.corpAttrId"
                :data="corpAtrrList"
                placeholder="客户属性"
                :loading="selectLoading"
                title="客户属性"
                :props="{
                  id: 'id',
                  label: 'attrValueDesc'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="projectId">
              <e6-vr-select
                v-model="searchForm.projectId"
                :data="projectList"
                :loading="selectLoading"
                placeholder="所属项目"
                title="所属项目"
                clearable
                :props="{
                  id: 'projectId',
                  label: 'projectName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="sceneName">
              <el-input
                v-model="searchForm.sceneName"
                placeholder="场景名称"
                title="场景名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="taskFirstClassList">
              <e6-vr-select
                v-model="searchForm.taskFirstClassList"
                :data="taskTypeList"
                placeholder="任务单类型"
                title="任务单类型"
                :loading="selectLoading"
                multiple
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>

            <el-form-item class="search-item--1" prop="regionalIdList">
              <e6-vr-select
                v-model="searchForm.regionalIdList"
                :data="areaList"
                :loading="selectLoading"
                placeholder="所属大区"
                title="所属大区"
                clearable
                multiple
                :props="{
                  id: 'areaId',
                  label: 'areaName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="areaIdList">
              <e6-vr-select
                v-model="searchForm.areaIdList"
                :data="cityList"
                :loading="selectLoading"
                placeholder="所属片区"
                title="所属片区"
                clearable
                multiple
                :props="{
                  id: 'areaId',
                  label: 'areaName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="addressName">
              <el-input
                v-model="searchForm.addressName"
                placeholder="所属地区"
                title="所属地区"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createByName">
              <el-input
                v-model="searchForm.createByName"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="labelTypeList">
              <e6-vr-select
                v-model="searchForm.labelTypeList"
                :data="workTagsList"
                placeholder="标签选择"
                title="标签选择"
                clearable
                multiple
                :loading="selectLoading"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="taskStatusList">
              <e6-vr-select
                v-model="searchForm.taskStatusList"
                :data="workStatusList"
                placeholder="任务单状态"
                title="任务单状态"
                clearable
                :loading="selectLoading"
                multiple
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="roleGroup">
              <e6-vr-select
                v-model="searchForm.roleGroup"
                :data="roleGroupList"
                placeholder="首次联系人类型"
                title="首次联系人类型"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="signStatus">
              <e6-vr-select
                v-model="searchForm.signStatus"
                :data="signStatusList"
                placeholder="签收状态"
                title="签收状态"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="isDeliverInstall">
              <e6-vr-select
                v-model="searchForm.isDeliverInstall"
                :data="isDeliverInstallList"
                placeholder="送装方式"
                title="送装方式"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="warnType">
              <e6-vr-select
                v-model="searchForm.warnType"
                :data="warnTypeList"
                placeholder="项目标签"
                title="项目标签"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <!-- <el-form-item class="search-item--1" prop="consumingDuration">
              <e6-vr-select
                v-model="searchForm.consumingDuration"
                :data="consumingDurationList"
                placeholder="任务单耗时时长"
                title="任务单耗时时长"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item> -->
            <el-form-item class="search-item--1" prop="consumingDurationStart">
              <el-input
                v-model="searchForm.consumingDurationStart"
                placeholder="耗时时长始"
                title="耗时时长始"
                @input="
                  searchForm.consumingDurationStart = searchForm.consumingDurationStart.replace(
                    /[^0-9-]+/,
                    ''
                  )
                "
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="consumingDurationEnd">
              <el-input
                v-model="searchForm.consumingDurationEnd"
                placeholder="耗时时长止"
                title="耗时时长止"
                @input="
                  searchForm.consumingDurationEnd = searchForm.consumingDurationEnd.replace(
                    /[^0-9-]+/,
                    ''
                  )
                "
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="hasWarnReason">
              <e6-vr-select
                v-model="searchForm.hasWarnReason"
                :data="hasWarnReasonList"
                placeholder="是否维护超期原因"
                title="是否维护超期原因"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="warnStatusList">
              <e6-vr-select
                v-model="searchForm.warnStatusList"
                :data="warnStatusList"
                placeholder="超期预警"
                title="超期预警"
                clearable
                multiple
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="expectTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="期望上门时间"
                ref="effectiveDatePicker"
                v-model="searchForm.expectTime"
                title="期望上门时间"
                :picker-options="pickerOptions('searchForm.expectTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="期望上门时间（始）"
                end-placeholder="期望上门时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="finishTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="任务完成时间"
                ref="effectiveDatePicker"
                v-model="searchForm.finishTime"
                title="任务完成时间"
                :picker-options="pickerOptions('searchForm.finishTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="任务完成时间（始）"
                end-placeholder="任务完成时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="deadLineTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="关单核算时间"
                ref="effectiveDatePicker"
                v-model="searchForm.deadLineTime"
                title="关单核算时间"
                :picker-options="pickerOptions('searchForm.deadLineTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="关单核算时间（始）"
                end-placeholder="关单核算时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <el-button
            type="primary"
            @click="handleMaintenance"
            style="margin-right:10px;"
            v-has="306"
          >
            维护批量信息
          </el-button>
          <i class="e6-icon-add_line" title="创建工单" @click="createTask"></i>
          <i
            class="e6-icon-export_fill"
            title="导出"
            @click="handleExportDialog"
          ></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            :render-header="renderHeader"
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <div
                class="table-label"
                v-if="column.fieldName === 'tagValue' && row[column.fieldName]"
              >
                <span
                  class="label-box"
                  v-for="(item, index) in row.tagValue"
                  :key="index"
                >
                  {{ item }}
                </span>
              </div>
              <span
                v-else-if="column.fieldName === 'taskStatusName'"
                :class="[
                  'status',
                  row.taskStatus == 3 || row.taskStatus == 4
                    ? 'finish'
                    : row.taskStatus === 2
                    ? 'ongoing'
                    : ''
                ]"
              >
                {{ row[column.fieldName] }}
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
      <!-- 创建工单弹框 -->
      <add-task-dialog
        :showAdd="showAdd"
        @closeDialog="closeDialog"
        pageType="G7"
      ></add-task-dialog>
      <!-- 导出弹框 -->
      <export-dialog
        :exportVisible.sync="exportVisible"
        @confirm="handleExport"
        @refreshData="queryList"
        ref="exportDialog"
        :tableId="tableId"
      ></export-dialog>
    </div>
    <!-- 维护批量信息弹框 -->
    <maintenance-dialog
      :maintenanceVisible="maintenanceVisible"
      :taskIdList="taskIdList"
      @refresh="queryList"
      @closeMaintenanceDialog="closeMaintenanceDialog"
    ></maintenance-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import AddTaskDialog from "@/components/workOrderManage/addTaskDialog.vue";
import exportDialog from "@/components/exportDialog/index";
import { printError } from "@/utils/util";
import exportData from "@/mixins/exportData";
import inputPlus from "@/components/inputPlus";
import maintenanceDialog from "@/components/maintenanceDialog/maintenanceDialog.vue";
const nowTime = new Date().getTime(); // 打开页面时的时间戳
import {
  getG7TaskList,
  exportG7TaskList,
  getProjectSelect,
  findDownList,
  findCorpAttr,
  getListAreaAll,
  getColumn,
  getWorkCorpList
} from "@/api";
export default {
  name: "TaskList",
  data() {
    return {
      tableId: 7932,
      showAdd: false,
      searchForm: {
        taskNos: [],
        taskNumber: "", //任务单号
        corpId: "",
        companyNos: [],
        roleGroup: "",
        corpName: "",
        taskSourceList: [], //工单来源
        sourceNumber: "",
        tasktype: "", //任务单类型
        corpAttrId: "", //客户属性
        isDeliverInstall: "",
        taskStatusList: ["1", "2"], //任务单状态
        taskFirstClassList: [], //任务单类型
        sceneName: "", //场景名称
        projectId: "", //所属项目
        regionalIdList: [], //所属大区
        areaIdList: [], //所属片区
        addressName: "", //所属地区
        createByName: "", //创建人
        labelTypeList: [], //标签
        signStatus: "", //签收状态
        expectTime: [], //期望上门时间
        expectTimeStartDate: "", //期待解决时间开始
        expectTimeEndDate: "", //期待解决时间结束
        createTime: [nowTime - 2592000000], //创建日期
        createdTimeStartDate: nowTime - 2592000000, //创建开始日期 默认一个月
        createdTimeEndDate: "", //期待解决时间结束
        finishTime: [], //完成时间
        finishTimeStartDate: "", //期待解决时间开始
        finishTimeEndDate: "", //期待解决时间结束
        deadLineTime: [],
        deadLineTimeStart: "",
        deadLineTimeEnd: "",
        pageIndex: 1,
        pageSize: 20,
        warnType: "",
        consumingDuration: "",
        consumingDurationStart: "",
        consumingDurationEnd: "",
        hasWarnReason: "",
        warnStatusList: []
      },
      warnStatusList: [
        {
          id: 1,
          label: "正常"
        },
        {
          id: 2,
          label: "已预警"
        },
        {
          id: 3,
          label: "已超期"
        }
      ],
      hasWarnReasonList: [
        {
          id: 1,
          label: "已维护"
        },
        {
          id: 2,
          label: "未维护"
        },
        {
          id: 3,
          label: "部分维护"
        }
      ],
      warnTypeList: [],
      consumingDurationList: [],
      serviceCompanyList: [],
      isDeliverInstallList: [
        {
          codeName: "送装",
          codeValue: 1
        },
        {
          codeName: "寄装",
          codeValue: 2
        }
      ],
      roleGroupList: [],
      signStatusList: [],
      selectLoading: false,
      loading: false,
      taskSourceList: [], //工单来源
      areaList: [], //大区
      cityList: [], //片区
      addressList: [], //地区
      projectList: [], //项目列表
      corpAtrrList: [], //客户属性下拉框
      taskTypeList: [], //工单类型
      workStatusList: [], //工单状态下拉框
      workTagsList: [], //标签
      corpList: [], //客户名称下拉框
      total: 0,
      columnData: [],
      tableData: [], // 表格数据
      exportApi: exportG7TaskList,
      maintenanceVisible: false,
      taskIdList: []
    };
  },
  components: {
    AddTaskDialog,
    exportDialog,
    inputPlus,
    maintenanceDialog
  },
  mixins: [listPage, listPageReszie, base, exportData],
  watch: {
    // 期望解决时间
    "searchForm.expectTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.expectTime = this.searchForm.expectTime.reverse();
        }
        this.searchForm.expectTimeStartDate = val[0] || "";
        this.searchForm.expectTimeEndDate = val[1] || "";
      }
    },
    // 创建时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.createdTimeStartDate = val[0] || "";
        this.searchForm.createdTimeEndDate = val[1] || "";
      }
    },
    // 完成时间
    "searchForm.finishTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.finishTime = this.searchForm.finishTime.reverse();
        }
        this.searchForm.finishTimeStartDate = val[0] || "";
        this.searchForm.finishTimeEndDate = val[1] || "";
      }
    },
    // 关单核算时间
    "searchForm.deadLineTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.deadLineTime = this.searchForm.deadLineTime.reverse();
        }
        this.searchForm.deadLineTimeStart = val[0] || "";
        this.searchForm.deadLineTimeEnd = val[1] || "";
      }
    }
  },
  created() {
    this.loadCorpData();
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [
          getProjectSelect({}),
          findDownList([
            "taskFirstClass",
            "workStatus",
            "workTags",
            "taskSource",
            "outApplySignStatus",
            "roleGroup",
            "organization",
            "warnType",
            "consumingDuration"
          ]),
          findCorpAttr({ id: null }),
          getListAreaAll({ areaType: 1 }),
          getListAreaAll({ areaType: 2 })
        ];
        let [
          projectRes,
          taskTypeRes,
          corpAttrRes,
          areaRes,
          cityRes
        ] = await Promise.all(promiseList);
        //获取项目下拉框
        this.projectList = this.getFreezeResponse(projectRes, {
          path: "data"
        });
        this.warnTypeList = this.getFreezeResponse(taskTypeRes, {
          path: "data.warnType"
        });
        this.consumingDurationList = this.getFreezeResponse(taskTypeRes, {
          path: "data.consumingDuration"
        });
        this.serviceCompanyList = this.getFreezeResponse(taskTypeRes, {
          path: "data.organization"
        });
        //工单来源
        this.taskSourceList = this.getFreezeResponse(taskTypeRes, {
          path: "data.taskSource"
        });
        //首次联系人类型
        this.roleGroupList = this.getFreezeResponse(taskTypeRes, {
          path: "data.roleGroup"
        });
        //工单类型
        this.taskTypeList = this.getFreezeResponse(taskTypeRes, {
          path: "data.taskFirstClass"
        });
        this.signStatusList = this.getFreezeResponse(taskTypeRes, {
          path: "data.outApplySignStatus"
        });
        //标签
        this.workTagsList = this.getFreezeResponse(taskTypeRes, {
          path: "data.workTags"
        });
        //工单状态
        this.workStatusList = this.getFreezeResponse(taskTypeRes, {
          path: "data.workStatus"
        });
        //获取客户属性
        this.corpAtrrList = this.getFreezeResponse(corpAttrRes, {
          path: "data"
        });
        //获取大区
        this.areaList = this.getFreezeResponse(areaRes, {
          path: "data"
        });
        //获取片区
        this.cityList = this.getFreezeResponse(cityRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.searchForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    //加载客户名称
    async loadCorpData(val) {
      try {
        let res = await getWorkCorpList({
          pageNum: 1,
          pageSize: 200,
          corpName: val
        });
        //获取客户名称
        this.corpList = this.getFreezeResponse(res, {
          path: "data.array"
        });
      } catch (error) {
        printError(error);
      }
    },
    //查询表头
    async queryList() {
      try {
        this.loading = true;
        let res = await getColumn({
          tableId: this.tableId
        });
        this.columnData = res.data.configColumnList.filter(
          item => item.display
        );
        this.queryData();
      } catch (error) {
        printError(error);
      }
    },
    //查询全部任务单
    async queryData() {
      try {
        let res = await getG7TaskList(this.searchForm);
        let tableData = res.data.array;
        tableData.map(item => {
          item.tagValue = item.tagValue ? item.tagValue.split(",") : [];
        });
        this.tableData = tableData;
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.routerPush({
          name: "taskListManage/detail",
          params: {
            refresh: true,
            taskId: row.taskId
          }
        });
      }
    },
    //选中数据
    handleSelectionChange(columns) {
      this.taskIdList = columns.map(item => item.taskId);
    },
    handleMaintenance() {
      if (!this.taskIdList.length) {
        this.$message.warning("请先选择数据");
        return;
      }
      this.maintenanceVisible = true;
    },
    closeMaintenanceDialog() {
      this.maintenanceVisible = false;
      this.$refs.elTable.clearSelection();
    },
    toDetail() {
      this.routerPush({
        name: "taskListManage/detail",
        params: {
          refresh: true
        }
      });
    },
    //创建任务单
    createTask() {
      this.showAdd = true;
    },
    //关闭弹框
    closeDialog() {
      this.showAdd = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.table-label {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .label-box {
    padding: 0px 10px;
    border-radius: 20px;
    display: inline-block;
    border: 1px solid #46bfea;
    color: #46bfea;
    margin: 5px;
  }
}
.status {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  background: #2791f7;
  border-radius: 20px;
  border: 1px solid #2791f7;
  color: #fff;
}
.finish {
  background: #e19b39;
  border: 1px solid #e19b39;
}
.ongoing {
  background: #69c43b;
  border: 1px solid #69c43b;
}
</style>
