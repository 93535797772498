/*
 * @Description: 下载相关
 * @Author: WuPuyi
 * @LastEditors: LiangYiNing
 * @Date: 2021-03-24 16:38:40
 * @LastEditTime: 2022-02-25 14:10:32
 */

import XLSX from "xlsx";
import { printError } from "@/utils/util";

/**
 * 前端直接使用数据下载电子表格
 * @param {object} option 配置项
 * @param {array} option.data 导出的数据
 * @param {string} option.xlsxName 导出文件的名称
 */
export function exportXlsxByData(option = {}) {
  let { data = [], xlsxName = "电子表格" } = option;
  if (!data.length) {
    return;
  }
  // 新建空workbook，然后加入worksheet
  const ws = XLSX.utils.json_to_sheet(data);
  // 设置每列的列宽，10代表10个字符，注意中文占2个字符
  ws["!cols"] = [{ wch: 10 }, { wch: 30 }, { wch: 25 }];
  // 新建book
  const wb = XLSX.utils.book_new();
  // 生成xlsx文件(book,sheet数据,sheet命名)
  XLSX.utils.book_append_sheet(wb, ws, "数据详情");
  // 写文件(book,xlsx文件名称)
  XLSX.writeFile(wb, `${xlsxName}.xlsx`);
}

/**
 * 组件的导出方法
 * @param {vueComponent} This vue组件
 * @param {axiosApi} exportApi 请求api
 * @param {object} searchForm 请求入参 默认值为对应组件列表请求参数
 */
export async function exportData(This, exportApi, searchForm) {
  try {
    This.loading = true;
    let res = await exportApi(searchForm || This.searchForm);
    if (res.code == "OK") {
      let url = This.getFreezeResponse(res, {
        path: "data",
        defaultVal: ""
      });
      if (url) exportExcelByUrl(url);
      else {
        exportMessageHandle(This);
      }
    }
  } catch (error) {
    printError(error);
  } finally {
    This.loading = false;
  }
}

/**
 * 使用下载地址导出
 * @param {string} url 下载地址
 */
export function exportExcelByUrl(url) {
  let eLink = document.createElement("a");
  eLink.download = url;
  eLink.style.display = "none";
  eLink.href = url;
  eLink.target = "_blank";
  document.body.appendChild(eLink);
  eLink.click();
  URL.revokeObjectURL(eLink.href); // 释放URL 对象
  document.body.removeChild(eLink);
}

/**
 * 跳转导出任务中心提示
 * @param {vueComponent} This 下载地址
 */
export function exportMessageHandle(This) {
  This.$confirm("已生成导出任务，请前往导出任务中心查看。", "提示", {
    confirmButtonText: "前往查看",
    cancelButtonText: "我知道了",
    type: "success",
    center: true
  }).then(() => {
    This.routerPush({
      name: "system/downLoadCenter",
      params: {
        refresh: true
      }
    });
  });
}

export function startDownloadMedia(data) {
  let xhh = new XMLHttpRequest();
  xhh.open("get", data.url, true);
  xhh.setRequestHeader("Access-Control-Allow-Origin", "*");
  xhh.setRequestHeader("Content-Type", "application/octet-stream");
  xhh.responseType = "blob";
  xhh.onreadystatechange = () => {
    if (xhh.readyState === 4 && xhh.status === 200) {
      let name = data.name; //xhh.getResponseHeader("ajax-filename");
      // let mimeType = "video/mp4"; //xhh.getResponseHeader("ajax-mimeType");
      // let blob = new Blob([xhh.response], { type: mimeType });
      let blob = new Blob([xhh.response]);
      let csvUrl = URL.createObjectURL(blob);
      let link = document.createElement("a");
      document.body.appendChild(link); //创建的标签添加到body，解决Firefox下无法打开页面的问题
      link.href = csvUrl;
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      link.download = decodeURI(name);
      link.click();
      document.body.removeChild(link);
    }
  };
  xhh.send(null);
}
