<!--
 * @Description: 
 * @Author: ChenXueLin
 * @Date: 2022-12-01 13:58:04
 * @LastEditTime: 2023-03-10 16:29:47
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    title="选择需要导出的字段"
    :visible.sync="exportVisible"
    :close-on-click-modal="false"
    width="75%"
    v-loading="dialogLoading"
    :element-loading-background="loadingBackground"
    custom-class="custom-dialog"
    :before-close="closeDialog"
    @open="openDialog"
  >
    <el-checkbox
      :indeterminate="isIndeterminate"
      v-model="checkAll"
      @change="handleCheckAllChange"
      >全选</el-checkbox
    >
    <div style="margin: 15px 0;"></div>
    <el-checkbox-group v-model="excludeFields" @change="handleCheckedChange">
      <el-row :span="24">
        <div v-for="item in columnList" :key="item.columnId">
          <el-col :span="8">
            <div>
              <el-checkbox :label="item.fieldName" :title="item.fieldLabel">{{
                item.fieldLabel
              }}</el-checkbox>
            </div>
          </el-col>
        </div>
      </el-row>
    </el-checkbox-group>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import { getColumn } from "@/api";
export default {
  name: "exportDialog",
  components: {},
  data() {
    return {
      dialogLoading: false,
      isIndeterminate: false,
      checkAll: false,
      excludeFields: [],
      columnList: []
    };
  },
  mixins: [base],
  props: {
    exportVisible: {
      type: Boolean,
      default: false
    },
    tableId: {
      type: [Number, String]
    }
  },

  computed: {},
  watch: {},
  methods: {
    async initData() {
      try {
        this.dialogLoading = true;
        let promiseList = [getColumn({ tableID: this.tableId })];
        let [columnRes] = await Promise.all(promiseList);
        this.columnList = columnRes.data.configColumnList;
      } catch (error) {
        console.error(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    handleCheckAllChange(val) {
      this.excludeFields = val
        ? this.columnList.map(item => item.fieldName)
        : [];
      this.isIndeterminate = false;
    },
    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.columnList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.columnList.length;
    },
    openDialog() {
      this.excludeFields = [];
      this.initData();
    },
    //关闭弹框
    closeDialog() {
      this.isIndeterminate = false;
      this.excludeFields = [];
      this.$emit("update:exportVisible", false);
    },
    handleSubmit() {
      let arr = this.columnList
        .filter(item => {
          return this.excludeFields.indexOf(item.fieldName) == -1;
        })
        .map(item => item.fieldName);
      console.log(arr);
      this.closeDialog();
      this.$emit("confirm", arr);
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 15px 20px;
    box-sizing: border-box;

    .el-form {
      margin-top: 15px;
      .el-textarea {
        width: 350px;
      }
    }
    // .el-checkbox-group {
    // display: flex;
    // flex-wrap: wrap;
    // div {
    //   width: 200px;
    //   // .el-checkbox__label {
    //   //   width: 160px;
    //   //   overflow: hidden;
    //   //   white-space: nowrap;
    //   //   text-overflow: ellipsis;
    //   // }
    // }
    // }
  }
}
</style>
