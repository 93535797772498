<!--
 * @Description: 
 * @Author: LiangYiNing
 * @Date: 2022-01-12 11:07:12
 * @LastEditTime: 2023-02-20 15:12:54
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="input-plus-content">
    <el-input
      :placeholder="placeholder"
      :title="title"
      :clearable="clearable"
      :disabled="disabled"
      @input="inputHandle"
      @clear="clearhandle"
      v-model="inputValue"
    >
      <el-button
        slot="append"
        :disabled="disabled"
        icon="el-icon-more"
        @click="openDialog"
      ></el-button>
    </el-input>
    <el-dialog
      v-dialogDrag
      :title="dialogTitle"
      :visible.sync="visible"
      width="620px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      custom-class="edit-customer-dialog"
      append-to-body
    >
      <div class="el-form">
        <div
          class="label"
          :style="{
            width: labelWith + 'px'
          }"
        >
          {{ label }}
        </div>
        <div
          class="input-content"
          :style="{
            width: `calc(100% - ${labelWith}px)`
          }"
        >
          <el-input
            type="textarea"
            v-model="textareaValue"
            :autosize="{ minRows: 3, maxRows: 5 }"
            :placeholder="placeholder"
            :title="title"
            @input="textareaInput"
          ></el-input>
          <div class="tips">
            <i class="el-icon-warning-outline"></i>
            多个请用
            <span class="redcolor">逗号</span>
            <span class="redcolor">分号</span>
            <span class="redcolor">空格</span>
            <span class="redcolor">回车</span>
            分隔
          </div>
        </div>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { typeOf } from "@/utils/util";
let VALUE_TYPE_ARRAY = "Array";
export default {
  name: "InputPlus",
  data() {
    return {
      inputValue: "",
      textareaValue: "",
      visible: false,
      loadingBackground: "#FFF"
    };
  },
  watch: {
    value() {
      if (this.valueType === VALUE_TYPE_ARRAY) {
        if (!this.testValueChange()) {
          this.inputValue =
            typeOf(this.value) === VALUE_TYPE_ARRAY
              ? this.value.join(",")
              : this.value;
        }
      } else {
        this.inputValue = this.value;
      }
    }
  },
  props: {
    value: {
      type: [String, Number, Array]
    },
    isNumber: {
      type: Boolean,
      default: false
    },
    valueType: {
      type: String,
      default: "String" // Array/String
    },
    placeholder: {
      type: String
    },
    title: {
      type: String
    },
    label: {
      type: String,
      default: "输入值"
    },
    clearable: {
      type: Boolean
    },
    dialogTitle: {
      type: String,
      default: "多个搜索"
    },
    labelWith: {
      type: Number,
      default: 80
    },
    splitReg: {
      type: RegExp,
      default() {
        return /\n|,|，|；|;| /g;
      }
    },
    maxLength: {
      type: Number,
      default: -1
    },
    disabled: {
      type: Boolean
    }
  },
  methods: {
    // value为数组类型时测试值是否在外部发生改变
    testValueChange() {
      let inputValue = this.inputValue.split(this.splitReg).join(",");
      let valueString = this.value.join(",");
      return inputValue === valueString;
    },
    // input输入事件
    inputHandle(value) {
      // 'value=value.replace(/[^\d]/g,''
      let val = this.isNumber ? value.replace(/[^\d]/g, "") : value;
      let list = val.split(this.splitReg);
      if (this.maxLength !== -1 && list.length > this.maxLength) {
        this.$message.warning(`已超出输入个数${this.maxLength}的限制`);
        list.length = this.maxLength;
        this.inputValue = this.inputValue.slice(0, -1);
      }
      if (this.valueType === VALUE_TYPE_ARRAY) {
        this.value.splice(0, this.value.length, ...list);
        this.$emit(
          "input",
          this.value.filter(item => item)
        );
      } else {
        this.$emit("input", val);
      }
    },
    textareaInput(value) {
      this.textareaValue = this.isNumber
        ? value.replace(/[^\d；;,，\n]/g, "")
        : value;
    },
    // 清除事件
    clearhandle() {
      let clearValue = "";
      if (this.valueType === VALUE_TYPE_ARRAY) {
        clearValue = [];
      }
      this.$emit("input", clearValue);
      this.$emit("clear", clearValue);
    },
    // 打开弹窗
    openDialog() {
      this.visible = true;
      this.textareaValue = this.inputValue;
    },
    // 关闭弹窗
    handleClose() {
      this.visible = false;
    },
    // 提交弹窗的输入结果
    handleSubmit() {
      let list = this.textareaValue.split(this.splitReg);
      if (this.maxLength !== -1 && list.length > this.maxLength) {
        this.$message.warning(`已超出输入个数${this.maxLength}的限制`);
        return;
      }
      this.inputValue = this.textareaValue;
      if (this.valueType === VALUE_TYPE_ARRAY) {
        this.value.splice(0, this.value.length, ...list);
        this.$emit(
          "input",
          this.value.filter(item => item)
        );
      } else {
        this.$emit("input", this.textareaValue);
      }
      this.$emit("change", this.textareaValue, list);
      this.handleClose();
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
.redcolor {
  color: #f00;
}
/deep/.edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    overflow: hidden;
  }
  .label {
    width: 100px;
    float: left;
    text-align: right;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .input-content {
    width: calc(100% - 100px);
    margin-bottom: 20px;
    float: left;
  }
}
</style>
