/*
 * @Description: 导出的公共逻辑
 * @Author: LiangYiNing
 * @Date: 2021-12-17 14:53:55
 * @LastEditTime: 2022-04-22 13:37:29
 * @LastEditors: LiangYiNing
 */
import { exportData } from "@/utils/download";
export default {
  data() {
    return {
      exportVisible: false
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    //点击导出
    handleExportDialog() {
      this.exportVisible = true;
    },
    // 导出
    handleExport(excludeFields) {
      exportData(this, this.exportApi, {
        ...this.searchForm,
        excludeFields
      });
    }
  }
};
