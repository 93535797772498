<!--
 * @Description: 创建任务单弹出框
 * @Author: ChenXueLin
 * @Date: 2021-10-11 13:38:03
 * @LastEditTime: 2022-09-17 14:39:20
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="创建工单"
    :visible="showAdd"
    width="770px"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    :before-close="handleClose"
    custom-class="add-task-dialog"
  >
    <div class="dialog-content">
      <!-- <el-input
        placeholder="请输入内容"
        v-model="value"
        class="input-with-select"
      >
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input> -->
      <div class="work-order-list">
        <!-- 常用工单 -->
        <!-- <template>
          <div class="work-order-type">常用工单</div>
          <div class="list-content">
            <div
              class="work-order"
              v-for="(item, index) in allUseList"
              :key="index"
            >
              <div class="item" @click="toAddTaskPage(item.type)">
                <img :src="item.imgUrl" alt="" />
              </div>
              <div class="til">{{ item.title }}</div>
            </div>
          </div>
        </template> -->
        <!-- 任务类工单 -->
        <template>
          <div class="work-order-type">任务类工单</div>
          <div class="list-content">
            <div
              class="work-order"
              v-for="(item, index) in taskList"
              :key="index"
            >
              <div class="item" @click="toAddTaskPage(item.path)">
                <img :src="item.imgUrl" alt="" />
              </div>
              <div class="til">{{ item.title }}</div>
            </div>
          </div>
        </template>
        <!-- 服务工单 -->
        <template v-if="pageType != 'G7'">
          <div class="work-order-type">服务类工单</div>
          <div class="list-content">
            <div
              class="work-order"
              v-for="(item, index) in service"
              :key="index"
            >
              <div class="item" @click="toAddServicePage(item.path)">
                <img :src="item.imgUrl" alt="" />
              </div>
              <div class="til">{{ item.title }}</div>
            </div>
          </div>
        </template>
        <!-- 内部使用工单 -->
        <template v-if="pageType != 'G7'">
          <div class="work-order-type">内部使用工单</div>
          <div class="list-content">
            <div class="work-order" v-for="(item, index) in other" :key="index">
              <div class="item" @click="toAddOtherPage(item.path)">
                <img :src="item.imgUrl" alt="" />
              </div>
              <div class="til">{{ item.title }}</div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" >确定</el-button>
    </div> -->
  </el-dialog>
</template>
<script>
import base from "@/mixins/base";

export default {
  name: "",
  components: {},
  data() {
    return {
      value: "",

      service: [
        {
          title: "维修申请",
          path: "serviceListManage/addRepairService",
          imgUrl: require("@/assets/images/weixiu.png")
        },
        {
          title: "场景变更",
          path: "serviceListManage/addChangeScene",
          imgUrl: require("@/assets/images/changjingbiangeng.png")
        },
        {
          title: "开通申请",
          path: "serviceListManage/addOpenService",
          imgUrl: require("@/assets/images/kaitong.png")
        },
        {
          title: "报停申请",
          path: "serviceListManage/addDeviceStop",
          imgUrl: require("@/assets/images/baoting.png")
        },
        {
          title: "设置打印信息",
          path: "serviceListManage/addSetPrintInfo",
          imgUrl: require("@/assets/images/set.png")
        },
        {
          title: "对接申请",
          path: "serviceListManage/addDockingService",
          imgUrl: require("@/assets/images/docking.png")
        },
        {
          title: "咨询回复",
          path: "serviceListManage/addReplyService",
          imgUrl: require("@/assets/images/reply.png")
        }
      ],
      other: [
        {
          title: "协同单",
          path: "teamWork/teamWorkAdd",
          imgUrl: require("@/assets/images/changjingbiangeng.png")
        },
        {
          title: "客户回访单",
          path: "visiteManage/addVisitService",
          imgUrl: require("@/assets/images/huifang.png")
        }
      ]
    };
  },
  mixins: [base],
  props: {
    showAdd: {
      type: Boolean,
      default: false
    },
    pageType: {
      type: String,
      default: ""
    }
  },
  computed: {
    taskList() {
      let list = [
        {
          title: "维修任务",
          type: 3,
          imgUrl: require("@/assets/images/weixiu.png"),
          path: "taskListManage/addRepairOrder"
        },
        {
          title: "重装任务",
          type: 6,
          imgUrl: require("@/assets/images/chongzhuang.png"),
          path: "taskListManage/addReinstallOrder"
        },
        // {
        //   title: "拆卸任务",
        //   type: 5,
        //   imgUrl: require("@/assets/images/chaixie.png"),
        //   path: "taskListManage/addRemoveOrder"
        // },
        {
          title: "巡检任务",
          type: 4,
          imgUrl: require("@/assets/images/xunjian.png"),
          path: "taskListManage/addInspectionOrder"
        }
      ];
      if (this.pageType == "G7") {
        list = [
          {
            title: "维修任务",
            type: 3,
            imgUrl: require("@/assets/images/weixiu.png"),
            path: "taskListManage/addRepairOrder"
          },
          {
            title: "重装任务",
            type: 6,
            imgUrl: require("@/assets/images/chongzhuang.png"),
            path: "taskListManage/addReinstallOrder"
          }
        ];
      }
      return list;
    }
  },
  watch: {},
  methods: {
    handleClose() {
      this.$emit("closeDialog", "showAdd");
    },
    //创建任务工单
    toAddTaskPage(path) {
      this.routerPush({
        name: path,
        params: {
          refresh: true
        }
      });
      this.handleClose();
    },
    //创建服务工单
    toAddServicePage(path) {
      this.routerPush({
        name: path,
        params: {
          refresh: true
        }
      });
      this.handleClose();
    },
    //其他工单
    toAddOtherPage(path) {
      this.routerPush({
        name: path,
        params: {
          refresh: true
        }
      });
      this.handleClose();
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
.add-task-dialog {
  .title {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
  }
  .dialog-content {
    padding: 20px;
    box-sizing: border-box;
    .input-with-select {
      width: 240px;
    }
    .work-order-list {
      .work-order-type {
        font-size: 14px;
        color: #444446;
        font-weight: 550;
        margin-top: 20px;
      }
      .list-content {
        display: flex;
        flex-wrap: wrap;
        .work-order {
          margin-right: 10px;
          .item {
            width: 89px;
            height: 89px;
            border-radius: 5px;
            border: 1px solid #eae9ee;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 15px;
            & > img {
              width: 40px;
              height: 37px;
            }
          }
          .til {
            text-align: center;
            font-size: 14px;
            color: #676769;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
/deep/.add-task-dialog .el-dialog__body {
  height: 590px;
  overflow-y: auto;
}
</style>
